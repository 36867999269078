<template>
  <section :style="translateX"
           :id="`uuid-${$attrs.uuid}`"
           :class="[{'landscape': isLandscape},'homepage-overlay']">
    <div class="homepage-overlay__wrapper">
      <div class="homepage-overlay__brand-logo-container">
        <a href="#">
          <picture v-if="hpOverlayLogo.type === 'image'">
            <source
                :srcset="hpOverlayLogo.url"
                v-if="hpOverlayLogo.url"
            />
            <pg-image
                class="overlay-brand-logo"
                :lazy-src="hpOverlayLogo.url"
                :alt="hpOverlayLogo.media_name"
                :aria-label="$msg('common.homepage-overlay.logo-aria-label')"
            />
          </picture>
        </a>
      </div>
        <div v-for="(contentObj, index) in hpOverlayContent"
             :key="index"
             :class="'homepage-overlay__content side-' + index + indexHover"
        >
          <picture v-if="contentObj.hpOverlayMedia.type === 'image'" class="overlay-container-picture">
            <source
                media="(min-width:0) and (max-width:641px)"
                :srcset="contentObj.hpOverlayMediaMobile | mgnlMedia('url')"
                v-if="isMobile && contentObj.hpOverlayMediaMobile"
            />
            <source
                media="(min-width:0) and (max-width:641px)"
                :srcset="contentObj.hpOverlayMedia | mgnlMedia('url')"
                v-else-if="isMobile && !contentObj.hpOverlayMediaMobile"
            />
            <source
                v-else
                media="(min-width:641px)"
                :srcset="contentObj.hpOverlayMedia | mgnlMedia('url')"
            />
            <pg-image
                class="overlay-image"
                :lazy-src="contentObj.hpOverlayMediaMobile | mgnlMedia('url')"
                :alt="contentObj.hpOverlayMedia.media_name"
                aria-hidden="true"
            />
          </picture>
          <ul class="description-contents"
             @click="goTo(contentObj.overlayLink.url)"
             @keydown="handleKeyDown"
             @mouseenter="containerHover(index)"
             @mouseleave="containerHoverOut(index)" >
            <li v-if="contentObj.hpContentLogo">
              <picture>
                <source
                    :srcset="contentObj.hpContentLogo.url"
                    v-if="contentObj.hpContentLogo.url"
                />
                <pg-image
                    :tabindex="index"
                    class="content-logo"
                    :lazy-src="contentObj.hpContentLogo.url"
                    :alt="contentObj.hpContentLogo.media_name"
                    :data-url="contentObj.overlayLink.url"
                    :aria-label="ariaLabel(index)"
                />
              </picture>
            </li>
            <li v-else-if="contentObj.hpContentTextLogo" :tabindex="index" :data-url="contentObj.overlayLink.url">
              <h1 class="content-text-logo"> {{ contentObj.hpContentTextLogo }} </h1>
            </li>
            <li v-if="contentObj.hpContentSubText" :tabindex="index" :data-url="contentObj.overlayLink.url">
              <h2 class="content-subtext-logo"> {{contentObj.hpContentSubText}} </h2>
            </li>
            <li v-if="contentObj.hpContentDescriptionText" class="content-description" :tabindex="index" :data-url="contentObj.overlayLink.url">
              <p class="content-description-text"> {{contentObj.hpContentDescriptionText}} </p>
            </li>
          </ul>
        </div>
    </div>
    <pg-footer
        :legalNote="legalNote"
    ></pg-footer>
  </section>
</template>

<script>
import PgImage from "../Image/Image.vue";
import {mgnlComponentMixin} from "@wl-vue-mixins/mixins";
import router from '@wl-vue-utils/router.js'
import PgFooter from "../Footer/Footer.vue";

export default {
  mixins: [mgnlComponentMixin], // !!! all the mgnl components must be configured with mgnlComponentMixin !!!
  router: router,
  components: {
    PgFooter,
    PgImage,
  },

  data() {
    return {
      shouldScroll: true,
      scrollInterval: null,
      shouldHide: true,
      hideInterval: null,
      lastTouchEnd: 0,
      scrollBarWidth: 0,
      translateX: 0,
      indexHover: '',
      closeOverlay: false,
      isLandscape: false
    };
  },

  props: {
    hpOverlayLogo: {
      type: Object,
      default: {}
    },
    hpOverlayContent: {
      type: Array,
      default: () => []
    },
    legalNote: {
      type: String,
      default: ""
    }
  },

  computed: {
    isViewportLarge() {
      return window.innerWidth > 1920;
    },
    isMobile() {
      return window.matchMedia('only screen and (max-width: 640px)').matches;
    },
    checkIsLandscape() {
      return window.matchMedia('only screen and (max-width: 1366px)').matches && window.innerWidth > window.innerHeight;
    }
  },

  created() {
    const bodyEl = document.body;
    bodyEl.classList.add("disable-zoom");
    this.translateOverlay();
    this.handleResize();
  },

  mounted() {
    console.debug(this);
    window.addEventListener("orientationchange", function() {
      console.log(screen.orientation);
      location.reload(true);
    });
    window.addEventListener("resize", this.translateOverlay);
  },

  methods: {
    goTo(url) {
      console.log(`url = ${url}`);
      this.$router.replace(url).then(() => {
        this.$router.go(0);
      });
    },
    handleResize() {
      this.isLandscape = this.checkIsLandscape;
    },
    translateOverlay() {
      this.viewportMarginValue = this.isViewportLarge ? (window.innerWidth - 1920) / 2 : 0;
      this.translateX = `--overlay-translate:-${this.viewportMarginValue}px;`;
    },
    containerHover(index) {
        this.indexHover = ` hover-side-${index}`;
    },
    containerHoverOut() {
        this.indexHover = '';
    },
    ariaLabel(index) {
    return index === 0 ? this.$msg('common.homepage-overlay.left.logo-aria-label') : this.$msg('common.homepage-overlay.right.logo-aria-label')
    },
    analyticsData(el) {
      return {
        evCategory: 'homepage_overlay',
        evAction: el.document?.url || el.link?.url || el.trackingLabel,
        evLabel: `${el.campaignId || 'No Campaign'} | ${this.common.actpageFullUrl}`
      }
    },
    handleKeyDown(event) {
      //Accessibility redirect when press "Enter" on focused element
      if(event?.key === "Enter") {
        const focusedEL = document.activeElement;
        const url = focusedEL?.getAttribute('data-url');
        url ? this.goTo(url) : undefined;
      }
    }
  }
}
</script>
