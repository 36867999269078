<template>
  <div>
    <input hidden class="isFirstLogin" data-is-first-login="false"/>
    <pg-alert v-if="showPromoAlert" color="primary" style="position:fixed;z-index:5;width: 100%;">
      <a class="promo-alert__link" :href="`#${targetId}`" @click.prevent='click(targetId)'>
        <i :class="`icon-gift-fill`" aria-hidden="true"></i>
        <msg id="common.promo-alert.message.text" />
      </a>
    </pg-alert>
    <input v-if="getCommunityHomePagePath" hidden class="community-homepage-link" :data-link="getCommunityHomePagePath">
    <header :class="classes()" id="header" ref="header" :aria-label="ariaLabel" v-on:scroll="handleScroll">
      <nav>
        <div class="header__logo">
          <a :href="getCustomHomepagePath ? getCustomHomepagePath : getHomepageNodePath" class="header__logo__on-background">
            <pg-image :lazy-src="logo | mgnlMedia('url')" :alt="logoAlt" :aria-label="$msg('common.header.logo-aria-label')"></pg-image>
          </a>
          <a :href="getCustomHomepagePath ? getCustomHomepagePath : getHomepageNodePath" class="header__logo__on-tranparent">
            <pg-image :lazy-src="logoOnTransparent | mgnlMedia('url')" :alt="logoAlt" :aria-label="$msg('common.header.logo-aria-label')"></pg-image>
          </a>
        </div>
        <div class="header-skip-navigation" :aria-label="$msg('common.header.skip-navigation.aria-label')">
          <a class="show-on-focus" href="#mainContent">
            <msg id="common.header.skip-navigation.aria" />
          </a>
        </div>
        <!-- div class="header__menu-shortcuts" v-if="items.link">
          <ul>
            <li v-for="item in items" :key="item.uuid">
              <a :href="item.link | mgnlLink('url')" :targetBlank="item.onBlank">{{item.label}}</a>
            </li>
          </ul>
        </div -->
        <div class="header__actions">
          <input hidden class="screenset-login-data" data-screenSet="" data-startScreen="">
          <div  v-if="(common.communityData && common.communityData.isCommunityEnabled) && (common.actpageTemplate != 'piaggio:pages/t-landing-page')"
                class="header__login-gigya">
            <a id="account-login" class="link_login_gigya" href="#" data-target="">
              <span>{{ common.communityData.headerTitle }}</span>
            </a>
          </div>
          <div class="header__menu-services">
            <ul v-if="services.length">
              <li
                  v-for="service in services"
                  :key="service.uuid"
                  :class="`header__menu-services__${service.name}`"
              >
                <!--  {{service.name}}-->
                <pg-button
                    :href="service.link | mgnlLink('url')"
                    :styleType="'icon'"
                    :icon="serviceClasses(service.name)"
                >
                  <msg :id="`common.header.${service.name}.aria`" cssClass="show-for-sr"/>
                </pg-button>
              </li>
            </ul>
            <pg-button
                v-if="!hideNavigation"
                :selfManageStatus="false"
                eventName="header:toggleNavigation"
                styleType="icon"
                class="header__menu-services__nav"
                :aria-expanded="isNavigationOpen"
                icon="icon-menu"
                aria-controls="navigation"
            >
              <msg id="common.header.hamburger-menu.aria" />
            </pg-button>
          </div>
        </div>
      </nav>
    </header>

    <div v-if="isLogged && isModalOpen" class="header__login-modal">
      <div class="modal-heading">
        <div class="modal-links">
          <pg-button
              :href="getCommunityHomePagePath"
              class="modal-option"
          >
            <msg :id="`common.community.${this.brandNodeName.toLowerCase()}.cta.private-homepage`" />
          </pg-button>
          <pg-button
              :href="getCommunityProfilePath"
              class="modal-option"
          >
            <msg id="common.community.cta.personal-area" />
          </pg-button>
        </div>
        <pg-button
            aria-controls="close"
            :aria-expanded="isModalOpen"
            class="modal-close-btn"
            eventName="header:modalClose"
            icon="icon-close"
            styleType="icon"
        >
          <msg id="common.generic.close" />
        </pg-button>
      </div>
      <div class="modal-separator"></div>
      <pg-button
          eventName="header:logout"
          class="modal-logout"
      >
        <msg id="common.community.cta.logout" />
      </pg-button>
    </div>
  </div>
</template>
<script>
import PgButton from "../Button/Button.vue";
import PgAlert from "../Alert/Alert.vue";
import PgImage from "../Image/Image.vue";
import Editorial from "../Editorial/Editorial.vue";
import {mgnlComponentMixin} from "../../mixins/mixins";
import onAfterScreenLoad from "../GigyaCommunity/gigya-script/onAfterScreenLoad";
import router from '../../utils/router.js'

export default {
  mixins: [mgnlComponentMixin], // !!! all the mgnl components must be configured with mgnlComponentMixin !!!
  router: router,
  name: "pg-header",
  components: {
    Editorial,
    PgButton,
    PgAlert,
    PgImage
  },
  data() {
    return {
      lastScrollTop: 0,
      statusClass: "",
      isNavigationOpen: "false",
      showPromoAlert: false,
      targetId: null,
      isLogged: false,
      isModalOpen: false,
      logoutRedirect: "",
      registrationRedirect: "",
      loginRedirect: "",
      forgotPswRedirect: "",
      faqRedirect: "",
      policyRedirect: "",
      gigyaAfterLoadScript: onAfterScreenLoad
    };
  },
  props: {
    commonMock: {
      type: Object,
      default: () => {}
    },
    layout: {
      type: String
    },
    // removed from facade, but kept to maintain old data structures in mocks and Jcr
    items: {
      type: Array,
      default: () => []
    },
    logo: {
      type: Object,
      default: () => {}
    },
    logoOnTransparent: {
      type: Object,
      default: () => {}
    },
    services: {
      type: Array,
      default: () => []
    },
    brandNodeName: {
      type: String
    },
    brandNodePath: {
      type: String
    },
    homepageNodePath: {
      type: String
    },
    customLogoLink: {
      type: String
    },
    hideNavigation: {
      type: Boolean,
      default: false
    },
    commonHeaderAriaLabel: {
      type: String,
      default: ''
    }
  },
  computed: {
    getHomepageNodePath() {
      return this.homepageNodePath ? this.homepageNodePath : this.common.homepageNodePath;
    },
    getCustomHomepagePath(){
      return this.common.customLogoLink;
    },
    getBrandNodePath() {
      return this.brandNodeName ? this.brandNodeName.toLowerCase() : this.common.brandNodeName;
    },
    getCommunityHomePagePath() {
      if (this.common.communityData !== undefined && this.common.communityData.homePageCommunity) {
        return this.common.communityData.homePageCommunity;
      } else return "";
    },
    getCommunityProfilePath() {
      if (this.common.communityData !== undefined && this.common.communityData.profileRedirectLink) {
        return this.common.communityData.profileRedirectLink;
      } else return "";
    },
    isPrivateTemplate() {
      if (this.common.communityData !== undefined && this.common.communityData.isPrivateTemplate) {
        return this.common.communityData.isPrivateTemplate;
      } else return "";
    },
    logoAlt() {
      return this.getBrandNodePath ? this.$msg('common.header.logo-label.text') + " " + this.getBrandNodePath : "";
    },
    ariaLabel() {
      //retrieving label text from dictionary in root element data. Otherwise, load label from common mock
      return this.$msg('common.header.aria-label.text') || this.commonHeaderAriaLabel;
    }
  },
  methods: {
    classes() {
      return ["header", this.layout, this.statusClass, this.showPromoAlert && 'header--promo', this.common.isOverlayConfigured ? 'not-visible' : ''];
    },
    isThemeVespa() {
      // Check if the body contains the class "theme-vespa"
      return document.body.classList.contains('theme-vespa');
    },
    serviceClasses(service) {
      switch (service) {
        case "dealer-locator": {
          if (this.isThemeVespa() === true) {
            return "icon-vespa_pin";
          }
          return "icon-pin-fill";
          break;
        }
        /*case "personal-area":
          return "icon-user-fill";
          break;
        case "search":
          return "icon-search-fill";
          break;*/
      }
    },
    handleScroll(event) {
      let st =
          (window && window.pageYOffset) ||
          (document && document.documentElement.scrollTop);

      if (st > this.lastScrollTop) {
        this.statusClass = "header--sticky";
      } else {
        //console.log("scroll up");
        this.statusClass = "header--sticky";
      }

      if (st === 0) {
        this.statusClass = "";
      }

      this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    },
    click(targetID) {
      const marginToTop = 144;
      const elTarget = document.getElementById(targetID);
      if (elTarget) {
        const bounding = elTarget.getBoundingClientRect();
        const distanceToTop = Math.floor(bounding.top + window.pageYOffset - marginToTop);
        window.scrollTo(0, distanceToTop);
      }
    },
    showScreen() {
      let screenSet = document.querySelector(".screenset-login-data").dataset.screenSet;
      let startScreen = document.querySelector(".screenset-login-data").dataset.startScreen;
      let errorLabel = this.$msg(`common.community.${this.brandNodeName.toLowerCase()}.error-message.invalid-login`);

      gigya.accounts.showScreenSet({
        screenSet:screenSet,
        startScreen:startScreen,
        customLang: {
          'invalid_login_or_password' : '<p class="error-message-description">' + errorLabel + '</p>'
        },
        onAfterScreenLoad: this.onAfterScreenLoad
      });
    },
    onAfterScreenLoad(event) {
      this.gigyaAfterLoadScript(event, this);
    },
    async loadScript() {
      let ctx = this;
      return new Promise((res, rej) => {
        ctx.showScreen();
      });
    },
    async loadAccountInfo(script, src, screenSet, startScreen) {
      let ctx = this;
      return new Promise((res, rej) => {
        script.onload = function () {
          res();
          ctx.onLoadPage();
        }
        script.onerror = function () {
          rej();
        }
      });
    },
    async onFirstLogin(brandName, country, response) {
      let setAccountInfoResponse = function (response) {
        if (response.errorCode === 400006) {
          console.log('Error :' + response.errorMessage);
          return false;
        }
      }

      let fO_TheClan = response.data?.FO_TheClan ? response.data.FO_TheClan.trim() : undefined;
      let iscrizione_TheClan = response.data.Iscrizione_TheClan;
      let fO_ProudOwners = response.data?.FO_ProudOwners ? response.data.FO_ProudOwners.trim() : undefined;
      let iscrizione_ProudOwners = response.data.Iscrizione_ProudOwners;

      console.debug("TheClan ",fO_TheClan,'\n',"Iscrizione The Clan ", iscrizione_TheClan,'\n',"f0 Proud Owners ", fO_ProudOwners,'\n',"iscrizione Proud Owners ''", iscrizione_ProudOwners);
      let params = {};
      if (brandName === "moto-guzzi" && country !== "US" && country !== "CA") {
        if ((!fO_TheClan && !iscrizione_TheClan) && (fO_ProudOwners === undefined && iscrizione_ProudOwners === undefined)) {
          params = {
            data: {
              FO_TheClan: "F",
              Iscrizione_TheClan: true
            },
            callback: setAccountInfoResponse
          }
        }
         //params.data è per la prima volta che ci iscriviamo
        localStorage.setItem("FO", params.data ?  fO_TheClan : fO_ProudOwners !== undefined ? fO_ProudOwners : fO_TheClan);
      } else if (brandName === "moto-guzzi" && (country === "US" || country === "CA")) {
        if (!fO_ProudOwners && !iscrizione_ProudOwners && (fO_TheClan === undefined && iscrizione_TheClan === undefined)) {
          params = {
            data: {
              FO_ProudOwners: "F",
              Iscrizione_ProudOwners: true
            },
            callback: setAccountInfoResponse
          }
        }
        localStorage.setItem("FO", params.data ?  fO_ProudOwners : fO_TheClan !== undefined ? fO_TheClan : fO_ProudOwners);
      } else if (brandName === "aprilia") {
        if (!response.data.FO_Bearacer && !response.data.Iscrizione_BeaRacer) {
          params = {
            data: {
              FO_Bearacer: "F",
              Iscrizione_BeaRacer: true
            },
            callback: setAccountInfoResponse
          }
        }
        localStorage.setItem("FO", params.data ?  params.data.FO_Bearacer : response.data.FO_Bearacer);
      }
      gigya.accounts.setAccountInfo(params);
    },
    checkForRedirect(){
      if (this.common.communityData.visibleOnlyForOwner && localStorage.FO === "F"){
        this.$router.replace(this.common.communityData.listingEventsRedirectLink).then(() => {
          this.$router.go(0);
        });
      }
    },
    onLoadPage() {
      let ctx = this;
      const brandName = ctx.common.brandNodeName;
      const country = ctx.common.currentCountry;
      let data;
      let getAccountInfoResponse = function (response) {
        if (response.errorCode !== 0) {
          console.log('Error :' + response.errorMessage);
          return false;
        }
        let isMobile = window.screen.width <= 767;
        let containsLoginCookie = ctx.containsLoginCookie();
        data = response.data;
        ctx.onFirstLogin(brandName, country, response);
        if (containsLoginCookie) {
          document.getElementById('account-login').classList.add("logged");
          const userName = response.profile['firstName'].length > 28 ? response.profile['firstName'].slice(0, 28) + '...' : response.profile['firstName']
          if (!isMobile) {
            document.getElementById('account-login').innerHTML = '<span>' + userName + '</span>';
          }
          localStorage.setItem('user', JSON.stringify(response.profile));
          localStorage.setItem('data', JSON.stringify(response.data));

        } else {
          document.getElementById('account-login').classList.remove("logged");
          if (isMobile) {
            document.getElementById('account-login').innerHTML = 'span>' + this.headerTitle + '</span>';

          }
          localStorage.removeItem('user');
        }
        localStorage.setItem("gigyaUid",response.UID);

        let vin = "noVin";
        if (brandName === "moto-guzzi" && country !== "US" && country !== "CA") {
          vin = data.MembershipCode_TheClan;
        } else if (brandName === "moto-guzzi" && (country === "US" || country === "CA")) {
              vin = data.MembershipCode_ProudOwners;
        } else if (brandName === "aprilia") {
          vin = data.MembershipCode_BeaRacer;
        }
        localStorage.setItem("qrCodeVin",vin);

        if (ctx.common.communityData.isPrivateCommunityHomepagePage) {
          ctx.hideShowCommunityCards();
        }
        if (ctx.common.communityData.isPrivateCommunityEventDetailPage){
          ctx.checkForRedirect();
        }
      };

      gigya.accounts.getAccountInfo({
        include: 'profile, data',
        callback: getAccountInfoResponse
      });
    },
    checkResultScript() {
      if (this.isLogged) {
        this.isModalOpen = !this.isModalOpen;
        this.$emit('gigya-modal-overlay', this.isModalOpen);
      } else {
        this.loadScript()
            .then(() => {
              console.log('Script loaded!');
            })
            .catch(() => {
              console.error('Script loading failed! Handle this error');
            });
      }
    },
    addingClickOnModal() {
      document.querySelector(".link_login_gigya").addEventListener("click", this.checkResultScript);
    },
    removingClickOnModal() {
      document.querySelector(".link_login_gigya").removeEventListener("click", this.checkResultScript);
    },
    loadGigyaScript(ctx) {
      const src = ctx.common.communityData.urlApiKey
      const screenSet = ctx.common.communityData.loginScreen.screenSet;
      const startScreen = ctx.common.communityData.loginScreen.startScreen;
      document.querySelector(".screenset-login-data").dataset.screenSet = screenSet;
      document.querySelector(".screenset-login-data").dataset.startScreen = startScreen;
      const script = document.createElement('script');
      script.type = "text/javascript";
      script.className = "gigya_import community"
      script.src = src;
      document.body.appendChild(script);
      if (this.common.communityData.isPrivateTemplate || this.containsLoginCookie) {
        this.loadAccountInfo(script, src, screenSet, startScreen)
            .then(() => {
              console.log('Account Info loaded!');
            })
            .catch(() => {
              console.error('Account Info loading failed! Handle this error');
            });
      }
    },
    deleteAllCookies() {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    },
    containsLoginCookie() {
      let containsLoginCookie = false;
      let cookieList = document.cookie.split(";");
      cookieList.forEach(cookie => {
        let key = cookie.split("=")[0].trim();
        let glt = key.split("_")[0];
        if (glt === "glt") {
          containsLoginCookie = true;
        }
      });
      this.isLogged = containsLoginCookie;
      return containsLoginCookie;
    },
    communityLoginCheck() {
      const containsLoginCookie = this.containsLoginCookie();
      if (!containsLoginCookie) {
        this.deleteAllCookies();
        this.$router.replace(this.logoutRedirect).then(() => {
          this.$router.go(0);
        });
        localStorage.clear();
      }
    },
    async hideShowCommunityCards(){
      let cards = document.querySelectorAll('.community-cards');
      cards.forEach(item => {
        const visibility = item.dataset.visibility;
        if (visibility === "all" || (visibility === "fan-community" && localStorage.FO === "F") || (visibility === "owner-community" && localStorage.FO === "O")){
          item.removeAttribute("style");
        } else {
          item.remove();
        }
      });
    },

    redirectOnEventDetail(){
      if (localStorage.getItem("redirectLink") != null) {
        const url = localStorage.getItem("redirectLink")
        this.$router.replace(url).then(() => {
          this.$router.go(0);
        });
        localStorage.removeItem("redirectLink")
      }
    }
  },
  created() {
    window && window.addEventListener("scroll", this.handleScroll);
    this.$eventHub.on("header:toggleNavigation", () => {
      this.isNavigationOpen =
          this.isNavigationOpen === "false" ? "true" : "false";
    });

    this.$eventHub.on("header:loggedUser", () => {
      if (this.isLogged) {
        this.isModalOpen = true;
        this.$emit('gigya-modal-overlay', this.isModalOpen);
      }
    });

    this.$eventHub.on("header:modalClose", () => {
      this.isModalOpen = false;
    });

    this.$eventHub.on("header:logout", () => {
      this.isModalOpen = false;
      gigya.accounts.logout();
      this.deleteAllCookies();
      localStorage.clear();
      this.$router.replace(this.logoutRedirect).then(() => {
        this.$router.go(0);
      });
    });

    this.$eventHub.on("alert:closeAlert", () => {
      this.showPromoAlert = false;
      document.body.classList.remove("has-promo");
    });

    // PIAGGIO-8402: integration with convert experiences (https://convert.elevio.help/en/articles/84999)
    if (this.brandNodeName?.toLowerCase() === 'moto-guzzi' && this.common.currentCountry === "IT") {
      console.log("IMPORTING CDN CONVERT EXPERIMENTS!");
      let convertScript = document.createElement('script');
      convertScript.setAttribute('src', 'https://cdn-4.convertexperiments.com/js/10022390-10025600.js');
      convertScript.setAttribute('type', 'text/javascript');
      document.head.appendChild(convertScript);
      console.log("APPENDED TO HEAD");
    }
  },
  destroyed() {
    window && window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    if (this.common.communityData) {
      if (this.common.communityData.isCommunityEnabled) {
        this.registrationRedirect = this.common.communityData.registrationRedirectLink;
        this.loginRedirect = this.common.communityData.loginRedirectLink;
        this.forgotPswRedirect = this.common.communityData.forgotPswRedirectLink;
        this.faqRedirect = this.common.communityData.faqRedirectLink;
        this.logoutRedirect = this.common.communityData.logoutRedirectLink;
        this.policyRedirect = this.common.communityData.policyRedirectLink;
        this.loadGigyaScript(this);
        this.addingClickOnModal(ctx);
        if (this.common.communityData.isPrivateTemplate) {
          this.communityLoginCheck();
          setTimeout(this.redirectOnEventDetail, 250);
        }
      }
    }
    // PIAGGIO-8402: integration with convert experiences (https://convert.elevio.help/en/articles/84999)
    this.$nextTick(function () {
      if (typeof (window.convert) != 'undefined') {
        console.log("CONVERT NEXT TICK ACTION")
        const exp = window.convert.currentData.experiments;
        for (let expID in exp) {
          window._conv_q = window._conv_q || [];
          window._conv_q.push(["executeExperiment", expID]);
        }
      }
    })
    const ctx = this;
    this.common.actpageTemplate && this.common.actpageTemplate.includes("t-model-version") &&
    this.$eventHub.on("cardPromotion:active-promo", (targetId) => {
      if (!this.showPromoAlert) {
        this.targetId = targetId;
        ctx.showPromoAlert = true;
        document.body.classList.add("has-promo");
      }
    })
  },

};
</script>
