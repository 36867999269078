<template>
  <div class="modal-overlay" :id="`uuid-${$attrs.uuid}`" />
</template>

<script>

export default {
  name: 'GigyaModalOverlay',
  components: {},
  }
</script>
