<template>
  <div class="navigation__menu-nested-container">
    <div :class="nestedClasses(item.productSelect)">
      <pg-button
          :aria-expanded="false"
          :event-params="{ uuid: item.uuid }"
          class="navigation-v2__menu-nested__back"
          event-name="expandNestedV2"
      >
        <i class="icon-arrow-sx" aria-hidden="true"></i>
        <h2 class="navigation__menu-nested__back-label">{{ item.label }}</h2>
      </pg-button>
      <!--h2 class="navigation__menu-nested__title">{{item.label}}</h2-->

      <ul class="nested-items-list" :class="[getNestedItemsLength(item.nestedItems.length), {'view-all-active' : viewAllLink}]" :aria-label="item.label" v-if="item.nestedItems.length > 0">
        <li v-for="subitem in item.nestedItems" v-bind:key="subitem.uuid">
          <a
              :href="subitem.url | mgnlLink('url')"
              :target="target(subitem.onBlank)"
              :rel="rel(subitem.noFollow, subitem.onBlank)"
              :linkNofollow="subitem.noFollow"
              class="navigation__menu-nested__item"
          >{{ subitem.label }}</a>
          <i class="icon-arrow-dx" aria-hidden="true"></i>
        </li>
      </ul>
      <a
          v-if="viewAllLink"
          :href="item.viewAllLink | mgnlLink('url')"
          class="navigation__menu-nested__view-all-link"
      >
        <msg :id="`common.header-v2.view-all-links`"></msg>
        <i class="icon-arrow-dx" aria-hidden="true"></i>
      </a>
    </div>
    <div v-if="hasHighlightItems" class="navigation__highlights-v2">
      <card-highlight-v2
          v-for="hlitem in item.highlightItems"
          :class="hlitem.ctaLinkStyle"
          :href="hlitem.link | mgnlLink('url')"
          :text="hlitem.text"
          :cta="hlitem.label"
          :img="hlitem.image | mgnlMedia('url')"
          :key="hlitem.uuid"
          :gradient="hlitem.imageGradient"
      ></card-highlight-v2>
    </div>
    <div v-else-if="hasPosterImage" class="navigation__background">
      <div>
        <pg-image :lazy-src="posterImage | mgnlMedia('url')"></pg-image>
      </div>
    </div>
  </div>
</template>

<script>
import PgButton from "../Button/Button.vue";
import PgImage from "../Image/Image.vue";
import CardHighlightV2  from "./CardHighlightV2.vue";

export default {
  name: "NavigationItemEditorialV2",

  components: {
    PgButton,
    CardHighlightV2,
    PgImage
  },

  props: {
    item: {
      type: Object,
      default: () => {}
    },
    productCardConf: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    },
    isActiveElement: {
      type: Boolean,
      default: false
    },
    highlightItems: {
      type: Array,
      required: false,
      default: () => []
    },
    posterImage: {
      type: Object,
      required: false,
      default: () => ({})
    },
    isEditInterface: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    viewAllLink() {
      return this.item.viewAllLink !== undefined && this.item.viewAllLink.url.length !== 0;
    },
    isMobile() {
      return window.screen.width <= 1024;
    },
    hasHighlightItems() {
      return this.highlightItems && this.highlightItems.length > 0;
    },
    hasPosterImage() {
      return this.posterImage && Object.keys(this.posterImage).length > 0;
    }
  },

  methods: {
    getNestedItemsLength(nestedItemsLength) {
      if(nestedItemsLength <= 4) {
        return "small";
      } else if (nestedItemsLength <= 6) {
        return "medium";
      } else if (nestedItemsLength <= 8) {
        return "long";
      } else if (nestedItemsLength <= 10) {
        return "x-long";
      } else {
        return "";
      }
    },
    target: function(isBlank) {
      return isBlank ? "_blank" : "_self";
    },
    rel: function(noFollow, targetBlank) {
      const attribute = targetBlank ? "noopener " : "";

      return noFollow ? attribute + "nofollow" : attribute;
    },
    nestedClasses: function(isProduct) {
      return ["navigation__menu-nested__menu", isProduct ? "navigation__menu-nested__menu--product" : ""];
    },
    analyticsData(el) {
      return {
        evCategory: "menu",
        evAction: "menu_principale",
        evLabel: `${this.item.label || "No Campaign"} | ${el.name}`
      };
    }
  }
};
</script>
