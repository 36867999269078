import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//routes from mgnl
function fetchMgnlRoutes() {

    return [
        { path: 'CardsWrapper', component: () => import('@wl-components/CardWrapper/CardsWrapper.vue') },
        { path: 'CardsWrapperCommunity', component: () => import('@wl-components/CardWrapperCommunity/CardsWrapperCommunity.vue') },
        { path: 'CardHighlightV2', component: () => import('@wl-components/MainNavigationV2/CardHighlightV2.vue') },
        { path: 'CarouselVehiclePremium', component: () => import('@wl-components/CarouselVehiclePremium/CarouselVehiclePremium.vue') },
        { path: 'Editorial', component: () => import('@wl-components/Editorial/Editorial.vue') },
        { path: 'FamilyShowcase', component: () => import('@wl-components/FamilyShowcase/FamilyShowcase.vue') },
        { path: 'HomepageOverlay', component: () => import('@wl-components/HomepageOverlay/HomepageOverlay.vue') },
        { path: 'Header', component: () => import('@wl-components/Header/Header.vue') },
        { path: 'HeaderV2', component: () => import('@wl-components/HeaderV2/HeaderV2.vue') },
        { path: 'ListingEvents', component: () => import('@wl-components/ListingEvents/ListingEvents.vue') },
        { path: 'ProductPresentationImmersive', component: () => import('@wl-components/ProductPresentationImmersive/ProductPresentationImmersive.vue') },
        { path: 'RecallCampaign', component: () => import('@wl-components/RecallCampaign/RecallCampaign.vue') },
        { path: 'SendVNKeyboard', component: () => import('@wl-components/SendVINKeyboard/SendVINKeyboard.vue') },
        { path: 'IframeDlMap', component: () => import('@wl-components/IframeDlMap/IframeDlMap.vue') },
        { path: 'FiltersBar', component: () => import('@wl-components/Listing/FiltersBar.vue') },
        { path: 'DealerLocatorDetails', component: () => import('@wl-components/DealerLocatorDetail/DealerLocatorDetail.vue') },
    ];
}

const router = new VueRouter({
    routes: fetchMgnlRoutes(),
    mode: 'history'
});

router.beforeEach((to, from, next) => {
    // Get the target URL, and handle both relative and absolute URLs
    let targetUrl = to.fullPath;
    console.log('targetUrl',targetUrl);
    const match = targetUrl.match(/(https?:\/\/|www\.)/);
    if (match && match.index !== undefined) {
        // Prendi la parte dell'URL a partire da "http" o "www"
        targetUrl = targetUrl.slice(match.index);
        console.log('targetUrl',targetUrl);

        if (targetUrl.startsWith('www')){
            targetUrl = 'https://' + targetUrl;
        }

        window.location.href = targetUrl;
    } else {
         next(); // Navigazione interna
    }


  });

export default router;
