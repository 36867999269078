<template>
  <div>
    <b-modal
        id="marketingToolModalContainer"
        :title="modalTitle"
        :googleMapCountryZoom="common.googleMapCountryZoom"
        hide-footer
        scrollable
        no-fade
        @hidden="resetModalState()"
        modal-class="modal--panel"
    >
      <template v-slot:modal-header-close>
        <i class="close__icon icon-close" aria-hidden="true"></i>
        <span class="close__label">
            <msg :id="'common.modal.close.text'"/>
          </span>
      </template>

      <component v-bind:is="formComponent" v-bind="formParams"/>

    </b-modal>
    <!-- Brand selection modal  -->
    <b-modal
        id="marketingToolBrandsModalContainer"
        :title="modalTitle"
        hide-footer
        scrollable
        no-fade
        modal-class="modal--panel"
    >
      <template v-slot:modal-header-close>
        <i class="close__icon icon-close" aria-hidden="true"></i>
        <span class="close__label">
            <msg :id="'common.modal.close.text'"/>
          </span>
      </template>

      <form-select-brand :options="options" @selected-brand="onBrandSelected"></form-select-brand>

    </b-modal>
  </div>
</template>
<script>
  import FormSelect from "@wl-components/FormSelect/FormSelect";
  import FormSelectBrand from "@wl-components/FormSelectBrand/FormSelectBrand";

  export default {

    name: "MarketingToolModal",

    components: {
      FormSelectBrand,
      FormSelect,
      FormContactDealer: () => import("../FormContactDealer/FormContactDealer"),
      FormDownloadBrochure: () => import("../FormDownloadBrochure/FormDownloadBrochure"),
      FormCustomerRequest: () => import("../FormCustomerRequest/FormCustomerRequest"),
      FormConfigurableCustomerRequest: () => import("../FormConfigurableCustomerRequest/FormConfigurableCustomerRequest"),
      FormTestRideRequest: () => import("../FormTestRideRequest/FormTestRideRequest")
    },

    data() {
      return {
        selectedBrand: 1,
        active: false,
        params: {},
        options: []
      }
    },

    props: {
      common: {
        type: Object,
        default: () => {
        }
      },
    },

    computed: {
      formComponent() {
        if (!this.params.marketingToolId) return;

        let component = "";

        switch (this.params.marketingToolId) {
          case "download-brochure":
            component = "form-download-brochure"
            break;
          case "test-ride-request-old":
            component = "form-test-ride-request"
            break;
          case "assistance-appointment":
          case "assistance-info":
          case "book-appointment": // DEPRECATO rif jira PIAGGIO-6509
            component = "form-contact-dealer";
            break;
          case "pre-booking":
          case "stay-updated":
          case "sale-appointment":
          case "sale-info":
          case "bp-aftersales-initiative":
          case "booking-aftersales":
          case "test-ride-request":
          case "test-ride-request-commercial-no-campaign":
            component = "form-configurable-customer-request";
            break;
          default:
            component = "form-customer-request";
        }

        return component;
      },
      formParams() {
        let isCampaign = this.params.campaignId ? `_${this.params.campaignId}` : '';
        switch (this.params.marketingToolId) {
          case 'sale-info':
          case 'sale-appointment':
          case 'stay-updated':
          case 'bp-aftersales-initiative':
          case 'booking-aftersales':
          case 'pre-booking':
          case 'test-ride-request':
            return {
              ...this.common,
              // fix PIAGGIO-5278 (tmp fix)
              'country': this.common.currentCountry,
              'lang': this.common.currentLanguage,
              'marketingToolName': this.params.marketingToolName,
              'precompiledParameters': this.params.precompiledParameters || {}
            };
          case 'pre-booking-old':
            return {
              ...this.common,
              'country': this.common.currentCountry,
              'lang': this.common.currentLanguage,
              'campaign-id': this.params.campaignId,
              'vehicleModel': this.params.vehicleModel,
              'vehicleColors': this.params.vehicleColors,
              'requestType': this.params.reason,
              'trackingLabel': this.params.trackingLabel,
              'interactionType': this.params.interactionType,
              'dealerSelector': true,
              'filterBusinessPartnerByServices': [`pre_booking${isCampaign}`],
              'precompiledParameters': this.params.precompiledParameters || {}
            };
          case 'pre-order':
            return {
              ...this.common,
              'country': this.common.currentCountry,
              'lang': this.common.currentLanguage,
              'campaign-id': this.params.campaignId,
              'requestType': this.params.reason,
              'trackingLabel': this.params.trackingLabel,
              'interactionType': this.params.interactionType,
              'dealerSelector': true,
              'vehicleFamilies': this.common.vehicles.families,
              'filterBusinessPartnerByServices': [`pre_order${isCampaign}`],
              'precompiledParameters': this.params.precompiledParameters || {}
            };
          case 'assistance-appointment':
          case 'assistance-info':
            return {
              ...this.common,
              'country': this.common.currentCountry,
              'lang': this.common.currentLanguage,
              'service-type': this.params.marketingToolId,
              'tracking-label': this.params.trackingLabel,
              'interactionType': this.params.interactionType,
              'selected-dealer': this.params.encodedDealercd,
              'precompiledParameters': this.params.precompiledParameters || {}
            };
          case 'test-ride-request-old':
            return {
              ...this.common,
              'country': this.common.currentCountry,
              'lang': this.common.currentLanguage,
              'campaign-id': this.params.campaignId,
              'requestType': this.params.reason,
              'vehicleFamilies': this.common.vehicles.families,
              'trackingLabel': this.params.trackingLabel,
              'interactionType': this.params.interactionType,
              'selected-dealer': this.params.encodedDealercd,
              'precompiledParameters': this.params.precompiledParameters || {}
            };
          default:
            return {
              'country': this.common.currentCountry,
              'lang': this.common.currentLanguage,
              'tracking-label': this.params.trackingLabel,
              'interactionType': this.params.interactionType,
              'precompiledParameters': this.params.precompiledParameters || {},
              ...this.common
            }
        }

      },
      modalTitle() {
        return this.$msg(`common.modal.header.${this.params.marketingToolId}.title`);
      },
    },
    methods: {
      onBrandSelected(brand) {
        this.common.brandCode = brand.brandCode;
        this.common.brandFormType = brand.brandFormType;
        this.common.brandName = brand.brandName;
        this.common.brandNodeName = brand.brandNodeName;
        this.common.brandNodePath = brand.brandNodePath;
        this.$set(this.params, "precompiledParameters", {hasBusinessPartner: false, encodedDealerCode: brand.encodedDealerCd});
        this.$bvModal.hide("marketingToolBrandsModalContainer")
        this.$bvModal.show("marketingToolModalContainer")
      },
      resetModalState() {
        this.params = {};
      }
    },
    mounted() {
      const ctx = this;
      this.$eventHub.on("MarketingTool:modal", (ctaParams) => {
        if (!ctaParams.marketingToolId) return;

        ctx.params = ctaParams;
        const isFake = ctx.common.isFakeBrand;

        if (isFake) {
          const currentLocale = ctx.common.currentLocale;
          const dealerCd = ctx.common.dealerCd;
          const reason = ctaParams.reason.replace("request.", "");
          const campaignId = ctaParams.campaignId;
          const service = campaignId? reason + "_" + campaignId : reason;

          this.$http.get("/action/availableBrands/?brandTypeFilter=realBrand&currentLocale=" + currentLocale + "&dealerCd=" + dealerCd + "&service=" + service)
              .then((data) => this.options = data.data.map((brand) => ({value: brand, text: brand.brandName})));
          ctx.$bvModal.show("marketingToolBrandsModalContainer");
        } else {
          ctx.$bvModal.show("marketingToolModalContainer");
        }

      });
    }
  }
</script>

