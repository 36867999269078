<template>
  <div id="vue-page-loaded" v-if="($root.dataLoaded || $config.editMode)">
    <gigya-modal-overlay v-if="isModalOpen" class="modal-overlay" />
    <pg-header
      v-if="!common.iframe && !common.isHeaderV2 && !common.isOverlayConfigured"
      :items="header.headerItems"
      :brandName="common.brandName"
      :brandNodeName="common.brandNodeName"
      :brandNodePath="common.brandNodePath"
      :services="header.services"
      :logo="header.logo"
      :logoOnTransparent="header.logoOnTransparent"
      :layout="header.listStyle"
      :hide-navigation="navigation.hideNavigation"
    />
    <pg-header-v2
        v-else-if="!common.iframe && common.isHeaderV2"
        :brandName="common.brandName"
        :brandNodeName="common.brandNodeName"
        :brandNodePath="common.brandNodePath"
        :customerServiceLink="header.customerServiceLink"
        :bookAppointmentLink="header.bookAppointmentLink"
        :services="header.services"
        :logo="header.logo"
        @toggle-contact="toggleContactUs"
        @header-store-service-ref="getStoreServiceRef"
        :logoOnTransparent="header.logoOnTransparent"
        :navigationMobileLogo="header.navigationMobileLogo"
        :menuItemsObj="mainNavigation.mainNavigationItemsV2"
        :common="common"
        :extraLink="header.extraLink"
        :extraLinkLabel="header.extraLinkLabel"
        :extraLinkIcon="header.extraLinkIcon"
        :extraLinkOnBlank="header.extraLinkOnBlank"
    />
    <contacts-v2
        :class="['contacts-us', {'show': isContactUsVisible}]"
        v-if="!common.iframe && common.isHeaderV2 && isContactUsVisible"
        :services="header.services"
        :customerServiceLink="header.customerServiceLink"
        :bookAppointmentLink="header.bookAppointmentLink"
        :storeServiceBtnWidth="storeServiceBtnWidth"
        :common="common"
    />
    <main-navigation
      v-if="!common.isHeaderV2 || !common.isMainNavigationV2 || !common.isOverlayConfigured"
      :menuItemsObj="mainNavigation.mainNavigationItems"
      :highlightItems="mainNavigation.highlightItems || []"
      :common="common"
    ></main-navigation>
    <floating-shortcuts
      v-if="!common.iframe && common.floatingShortcuts && common.floatingShortcuts.length > 0 && !common.isOverlayConfigured"
      :floatingShortcuts="common.floatingShortcuts"
      :actpageFullUrl="common.actpageFullUrl"
    />
    <santader-fin-sim
        v-if="common.santanderFinancialSimulator && common.santanderFinancialSimulator !== null && !common.isOverlayConfigured"
        :commonData="common"
        :modelData="model"
    ></santader-fin-sim>
    <!-- page vue component must declare a <slot>. It works like the <jsp:doBody/> -->

    <main id="mainContent">
      <homepage-overlay v-if="common.isOverlayConfigured" v-bind="hpOverlayData" ></homepage-overlay>
      <slot v-else></slot>
    </main>

    <pg-footer
      :logo="footer.logo"
      :items="footer.footerColumns || []"
      :brandName="common.brandName"
      :brandNodeName="common.brandNodeName"
      :site-service-param="common.siteServiceParam"
      :brandNodePath="common.brandNodePath"
      :languages="common.changeLanguages"
      :currentLocale="common.currentLocale"
      :socialLinks="common.socialLinks"
      :legalNote="footer.legalNote"
      :currentCountry="common.currentCountry"
      :currentLanguage="common.currentLanguage"
      :disableChangeLang="common.disableChangeLang"
      :includeCommunityFooter="model.includePreFooter"
      :communityFooterData="{
        iconName: common.communityData ? common.communityData.iconNamePreFooter : null,
        iconTitle: common.communityData ? common.communityData.iconTitlePreFooter : null,
        contact1: common.communityData ? common.communityData.contact1PreFooter : null,
        contact2: common.communityData ? common.communityData.contact2PreFooter : null
      }"
      v-if="!common.iframe && !common.isOverlayConfigured"
      :show-flags="common.showFlags"
    ></pg-footer>

    <marketing-tool-modal v-if="!common.iframe && !common.isOverlayConfigured" :common="common"/>
    <!-- BISOGNA AGIRE QUI PER IL PULSANTE GOTOTOP  -->
    <go-to-top v-if="model.goToTop || this.common.goToTop"></go-to-top>
  </div>

  <pg-loader v-else></pg-loader>
</template>

<script>
  import { mgnlComponentMixin } from "../mixins/mixins.js";
  import SantaderFinSim from "./SantanderFinSim/SantaderFinSim";
  import PgHeader               from "./Header/Header.vue";
  import PgHeaderV2             from "./HeaderV2/HeaderV2.vue";
  import ContactsV2 from "./HeaderV2/ContactsV2.vue";
  import MainNavigation from "./MainNavigation/MainNavigation.vue";
  import MainNavigationV2 from "./MainNavigationV2/MainNavigationV2.vue";
  import PgLoader from "./Loader/Loader.vue";
  import PgFooter from "./Footer/Footer.vue";
  import FloatingShortcuts from "./FloatingShortcuts/FloatingShortcuts.vue";
  import MarketingToolModal from "./MarketingToolModal/MarketingToolModal";
  import GoToTop from "./GoToTop/GoToTop";
  import HomepageOverlay from "./HomepageOverlay/HomepageOverlay";
  import GigyaModalOverlay from "./GigyaCommunity/GigyaModalOverlay.vue";

  export default {
    mixins: [mgnlComponentMixin],

    name: "VuePageLayout",

    data() {
      return {
        isContactUsVisible: false,
        storeServiceBtnWidth: null,
        isModalOpen: false,
      };
    },
    components: {
      HomepageOverlay,
      MainNavigation,
      MainNavigationV2,
      SantaderFinSim,
      PgHeader,
      PgHeaderV2,
      ContactsV2,
      PgFooter,
      PgLoader,
      MarketingToolModal,
      FloatingShortcuts,
      GoToTop,
      GigyaModalOverlay
    },
    computed: {
      navigation: function() {
        return this.model.navigation || {};
      },
      header: function() {
        return (this.navigation && this.navigation.header) || {};
      },
      mainNavigation: function() {
        if(this.navigation && this.navigation.mainNavigation) {
          if (Array.isArray(this.navigation.mainNavigation)) {
            if(this.common.isMainNavigationV2) {
              return "mainNavigationItemsV2" in this.navigation.mainNavigation[0] ? this.navigation.mainNavigation[0] : this.navigation.mainNavigation[1]
            } else {
              return "mainNavigationItems" in this.navigation.mainNavigation[0] ? this.navigation.mainNavigation[0] : this.navigation.mainNavigation[1]
            }
          } else {
            return this.navigation.mainNavigation
          }
        } else {
          return {}
        }
      },
      hpOverlayData: function() {
        if (this.model.main) {
          const values = Object.values(this.model.main);
          for (const value of values) {
            const keys = Object.keys(value);
            for (const key of keys) {
              if (key === "hpOverlayContent") {
                value["legalNote"] = this.footer.legalNote
                return value;
              }
            }
          }
        }
        return { uuid: undefined, title: undefined, hpOverlayLogo: undefined, hpOverlayContent: undefined, legalNotes: this.footer.legalNote };
      },
      footer: function() {
        return (
          (this.navigation && this.navigation.footerNavigation) || {}
        );
      }
    },

    methods: {
      toggleContactUs() {
        this.isContactUsVisible = !this.isContactUsVisible;
      },
      getStoreServiceRef(storeService) {
        if(storeService) {
          this.storeServiceBtnWidth = storeService.clientWidth;
        }
      }
    },

    created() {
      this.$eventHub.on('gigya-modal-overlay', (modalStatus) => {
        this.isModalOpen = modalStatus;
      })
      this.$eventHub.on("header:isNavigationOpen", (isNavigationOpen) => {
        if(isNavigationOpen) {
          this.isContactUsVisible = false;
        }
      });
    }
  };
</script>
